.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: relative;
  top: 100%;
  left: 0;
}
 /deep/ .el-textarea .el-input__count {
  line-height: 20px;
}
